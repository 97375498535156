import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1440p_Series/Network/Remote_Access/SuccessBox';
import PermissionBox from 'components/Web_User_Interface/1440p_Series/_permissions/SystemPerm';
import RemoteTable from 'components/Web_User_Interface/1440p_Series/Network/Remote_Access/remoteTable';
import DangerBox from 'components/Web_User_Interface/1440p_Series/Network/Remote_Access/DangerBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // Remote Access",
  "path": "/Web_User_Interface/1440p_Series/Network/Remote_Access/",
  "dateChanged": "2021-12-12",
  "author": "Mike Polinowski",
  "excerpt": "You have two ways to access your camera over the internet - the easy way is called P2P. The only way to get complete access to all your camera´s functions is called DDNS. The DDNS or Internet Address gives you access to your cameras web user interface.",
  "image": "./WebUI_1440p_SearchThumb_Network_Remote_Access.png",
  "social": "/images/Search/WebUI_1440p_SearchThumb_Network_Remote_Access.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1440p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Network Menu // Remote Access' dateChanged='2021-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='You have two ways to access your camera over the internet - the easy way is called P2P. The only way to get complete access to all your camera´s functions is called DDNS. The DDNS or Internet Address gives you access to your cameras web user interface.' image='/images/Search/WebUI_1440p_SearchThumb_Network_Remote_Access.png' twitter='/images/Search/WebUI_1440p_SearchThumb_Network_Remote_Access.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1440p_Serie/Netzwerk/Fernzugriff/' locationFR='/fr/Web_User_Interface/1440p_Series/Network/Remote_Access/' crumbLabel="Remote Access" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1440p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1440p-web-user-interface",
        "aria-label": "1440p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1440p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PermissionBox mdxType="PermissionBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`Your camera comes with a personal `}<a parentName="p" {...{
        "href": "/en/Internet_Access/The_DDNS_Service/"
      }}>{`DDNS Address`}</a>{` - e.g. `}<a parentName="p" {...{
        "href": "http://xxxx77.ddns-instar.de."
      }}>{`http://xxxx77.ddns-instar.de.`}</a>{` Everything you need for a remote access to your camera is a `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Port Forwarding Rule`}</a>{` in your router. You can access your camera afterwards through the internet using this http address. Please visit the DDNS section for detailed information on the `}<a parentName="p" {...{
        "href": "/en/Internet_Access/Port_Forwarding/"
      }}>{`Port Forwarding`}</a>{` setup as well as video instruction for many common routers. Or refer to your router's user manual for further guidelines on how to set up a port forwarding rule.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b745823a7f12bff2e6d695aa1acce6f/e2e58/1440p_Settings_Network_Remote_Access.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7rAAAO6wFxzYGVAAACZElEQVQoz02RXUsUURjH/QxRQaKEF+G662JoubrrezvOvozO7Mw58+76ApaZb6SJlhVZN4JaUF14Y1dlRARRQdB1X6Eu+jC/mBmtvfjx/OE553/+z3OastksHal2inPPmNj5THnzPcr6CTfunvGuQZ9QPO2Nr79FPPmCubRHS/NFmpubaWtroymdyZBqb6ccruFuvESu7iNWDzBXDrBWIw6xIt1A3FvZx998hRas0H21h66uHjKZLE2ZTIZ0Os3YUAF1bCCmOj6KOnqmR1BG8jHl4hCamvSUkeh8AU0pI4yblMcnyeX6E8OOjg5UtUQYTuEHIV6QVNfzsYTEcb1Y27ZDzbRipO0Q1KcIah6L+gymVqG/rzcxTKVSqKpKvV4n8GwCxyL0XcIwRAiB49hxjbDtREshqFmCWWnwYlUwP6cwNNhgWCqV8DwXObOEt/gQWb+NY0uEkLiu+88w0o7jxI/ousHy0gLHr7eYdgbI9+VoivZ3ljDwPeTcGu7SLvbsMm6cTMYGQlinaZ04ZVSr1Sq7u7v8+f6J7Wmda9d7GneoMjMzTT1wqfs2oe/g+0EynpSYpokV6dORbSmpahM82rrHr6/fOFqeJ9fb/d+wqChY0sGwbIR0YpPoYq1WwzCMpOqTGJMahqGjWybFSoWnW+v8/vmD4+1b+NXTX77SnkJoRT4+1vmwo7EwZWJYEimTdDHCwlnZQ9x/w8LsBkflKocVnTuhz/MHGywaCuXhnmSHUcJCXy92ZQhRGmBkME9/Pk8hnyffQGG0REGZZHhwDC3Xx0RhgGxnJ+fOX+BSSystrZf5C1hisJ6/h7A7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b745823a7f12bff2e6d695aa1acce6f/e4706/1440p_Settings_Network_Remote_Access.avif 230w", "/en/static/2b745823a7f12bff2e6d695aa1acce6f/d1af7/1440p_Settings_Network_Remote_Access.avif 460w", "/en/static/2b745823a7f12bff2e6d695aa1acce6f/7f308/1440p_Settings_Network_Remote_Access.avif 920w", "/en/static/2b745823a7f12bff2e6d695aa1acce6f/c986f/1440p_Settings_Network_Remote_Access.avif 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b745823a7f12bff2e6d695aa1acce6f/a0b58/1440p_Settings_Network_Remote_Access.webp 230w", "/en/static/2b745823a7f12bff2e6d695aa1acce6f/bc10c/1440p_Settings_Network_Remote_Access.webp 460w", "/en/static/2b745823a7f12bff2e6d695aa1acce6f/966d8/1440p_Settings_Network_Remote_Access.webp 920w", "/en/static/2b745823a7f12bff2e6d695aa1acce6f/b8544/1440p_Settings_Network_Remote_Access.webp 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b745823a7f12bff2e6d695aa1acce6f/81c8e/1440p_Settings_Network_Remote_Access.png 230w", "/en/static/2b745823a7f12bff2e6d695aa1acce6f/08a84/1440p_Settings_Network_Remote_Access.png 460w", "/en/static/2b745823a7f12bff2e6d695aa1acce6f/c0255/1440p_Settings_Network_Remote_Access.png 920w", "/en/static/2b745823a7f12bff2e6d695aa1acce6f/e2e58/1440p_Settings_Network_Remote_Access.png 1069w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b745823a7f12bff2e6d695aa1acce6f/c0255/1440p_Settings_Network_Remote_Access.png",
              "alt": "Web User Interface - 1440p Series - Network Remote Access",
              "title": "Web User Interface - 1440p Series - Network Remote Access",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <RemoteTable mdxType="RemoteTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox mdxType="DangerBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul">{`see also:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Quick_Installation/Internet_Protocol_IPv6/"
            }}>{`What is IPv6 and how does SLAAC work?`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/Frequently_Asked_Question/WQHD_IPv6_Portforwarding/"
            }}>{`Instructions for IPv6 port activation in an AVM Fritzbox`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`In case you want to use an account from `}<a href="http://dyn.com/dns/" target="_blank" rel="noopener noreferrer">{`DYNDNS`}</a>{` simply choose the third party service and type in your personal login credentials. By doing so you will temporarily `}<strong parentName="p">{`deactivate your INSTAR DDNS address`}</strong>{`. In case you are using several cameras behind a single internet access point, please set up the third party address in `}<strong parentName="p">{`only one`}</strong>{` of the cameras. Or if possible `}<strong parentName="p">{`directly inside your router`}</strong>{`! All your cameras will be accessible through this address - just assign a unique HTTP port to every camera. For example, if camera 1 is assigned the HTTP port 8081 and camera 2 the HTTP port 8082, use myaddress.dyndns.org:8081 to reach camera 1 and myaddresse.dyndns.org:8082 to be forwarded to camera 2.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      